export const FETCH_POSTS = "FETCH_POSTS";
export const FETCH_POSTS_SUCCEEDED = "FETCH_POSTS_SUCCEEDED";
export const FETCH_POST = "FETCH_POST";
export const FETCH_POST_SUCCEEDED = "FETCH_POST_SUCCEEDED";
export const FETCH_POST_FAILED = "FETCH_POST_FAILED";
// export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
// export const FETCH_CATEGORIES_SUCCEEDED = "FETCH_CATEGORIES_SUCCEEDED";
export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const FETCH_CATEGORY_SUCCEEDED = "FETCH_CATEGORY_SUCCEEDED";
export const FETCH_CATEGORY_FAILED = "FETCH_CATEGORY_FAILED";
export const FETCH_RELATEDPOSTS = "FETCH_RELATEDPOSTS";
export const FETCH_RELATEDPOSTS_SUCCEEDED = "FETCH_RELATEDPOSTS_SUCCEEDED";
export const FETCH_TAGS = "FETCH_TAGS";
export const FETCH_TAGS_SUCCEEDED = "FETCH_TAGS_SUCCEEDED";
export const FETCH_TAG = "FETCH_TAG";
export const FETCH_TAG_SUCCEEDED = "FETCH_TAG_SUCCEEDED";

export const FETCH_TAG_ARTICLES = "FETCH_TAG_ARTICLES";
export const FETCH_TAG_ARTICLES_SUCCEEDED = "FETCH_TAG_ARTICLES_SUCCEEDED";

export const FETCH_TAG_VIDEOS = "FETCH_TAG_VIDEOS";
export const FETCH_TAG_VIDEOS_SUCCEEDED = "FETCH_TAG_VIDEOS_SUCCEEDED";

export const FETCH_SEARCH_RESULT = "FETCH_SEARCH_RESULT";
export const FETCH_SEARCH_RESULT_SUCCEEDED = "FETCH_SEARCH_RESULT_SUCCEEDED";
export const FETCH_SEARCH_RESULT_FAILED = "FETCH_SEARCH_RESULT_FAILED";

export const FETCH_BRAND_PROFILE = "FETCH_BRAND_PROFILE";
export const FETCH_BRAND_PROFILE_SUCCEEDED = "FETCH_BRAND_PROFILE_SUCCEEDED";
export const FETCH_BRAND_PROFILE_FAILED = "FETCH_BRAND_PROFILE_FAILED";

export const FETCH_BRANDS = "FETCH_BRANDS";
export const FETCH_BRANDS_SUCCEEDED = "FETCH_BRANDS_SUCCEEDED";
export const FETCH_BRANDS_FAILED = "FETCH_BRANDS_FAILED";

export const FETCH_CATEGORY_MOBILE = "FETCH_CATEGORY";
export const FETCH_CATEGORY_MOBILE_SUCCEEDED = "FETCH_CATEGORY_SUCCEEDED";

export const FETCH_CATEGORY_DESKTOP = "FETCH_CATEGORY";
export const FETCH_CATEGORY_DESKTOP_SUCCEEDED = "FETCH_CATEGORY_SUCCEEDED";

export const FETCH_POPULAR_ITEMS = "FETCH_POPULAR_ITEMS";
export const FETCH_POPULAR_ITEMS_SUCCEEDED = "FETCH_POPULAR_ITEMS_SUCCEEDED";

export const FETCH_POPULAR_POSTS = "FETCH_POPULAR_POSTS";
export const FETCH_POPULAR_POSTS_SUCCEEDED = "FETCH_POPULAR_POSTS_SUCCEEDED";
export const FETCH_POPULAR_POSTS_FAILED = "FETCH_POPULAR_POSTS_FAILED";

export const FETCH_VIDEOS = "FETCH_VIDEOS";
export const FETCH_VIDEOS_SUCCEEDED = "FETCH_VIDEOS_SUCCEEDED";

export const FETCH_VIDEO = "FETCH_VIDEO";
export const FETCH_VIDEO_SUCCEEDED = "FETCH_VIDEO_SUCCEEDED";
export const FETCH_VIDEO_FAILED = "FETCH_VIDEO_FAILED";

export const FETCH_RELATED_VIDEOS = "FETCH_RELATED_VIDEOS";
export const FETCH_RELATED_VIDEOS_SUCCEEDED = "FETCH_RELATED_VIDEOS_SUCCEEDED";

export const FETCH_PLAYLIST_VIDEOS = "FETCH_PLAYLIST_VIDEOS";
export const FETCH_PLAYLIST_VIDEOS_SUCCEEDED = "FETCH_PLAYLIST_VIDEOS_SUCCEEDED";
export const FETCH_PLAYLIST_VIDEOS_FAILED = "FETCH_PLAYLIST_VIDEOS_FAILED";

export const FETCH_CATEGORY_VIDEOS = "FETCH_CATEGORY_VIDEOS";
export const FETCH_CATEGORY_VIDEOS_SUCCEEDED = "FETCH_CATEGORY_VIDEOS_SUCCEEDED";
export const FETCH_CATEGORY_VIDEOS_FAILED = "FETCH_CATEGORY_VIDEOS_FAILED";

export const FETCH_HOME_POSTS = "FETCH_HOME_POSTS";
export const FETCH_HOME_POSTS_SUCCEEDED = "FETCH_HOME_POSTS_SUCCEEDED";
export const FETCH_HOME_POSTS_FAILED = "FETCH_HOME_POSTS_FAILED";

export const FETCH_EVENTS = "FETCH_EVENTS";
export const FETCH_EVENTS_SUCCEEDED = "FETCH_EVENTS_SUCCEEDED";

export const FETCH_EVENT_DETAIL= "FETCH_EVENT_DETAIL";
export const FETCH_EVENT_DETAIL_SUCCEEDED = "FETCH_EVENT_DETAIL_SUCCEEDED";

export const FETCH_BRAND_VIDEOS = "FETCH_BRAND_VIDEOS";
export const FETCH_BRAND_VIDEOS_SUCCEEDED = "FETCH_BRAND_VIDEOS_SUCCEEDED";

export const FETCH_BRAND_EVENTS = "FETCH_BRAND_EVENTS";
export const FETCH_BRAND_EVENTS_SUCCEEDED = "FETCH_BRAND_EVENTS_SUCCEEDED";

export const FETCH_AUTHOR_PROFILE = "FETCH_AUTHOR_PROFILE";
export const FETCH_AUTHOR_PROFILE_SUCCEEDED = "FETCH_AUTHOR_PROFILE_SUCCEEDED";

export const FETCH_AUTHOR_VIDEOS = "FETCH_AUTHOR_VIDEOS";
export const FETCH_AUTHOR_VIDEOS_SUCCEEDED = "FETCH_AUTHOR_VIDEOS_SUCCEEDED";

export const FETCH_AUTHOR_ARTICLES = "FETCH_AUTHOR_ARTICLES";
export const FETCH_AUTHOR_ARTICLES_SUCCEEDED = "FETCH_AUTHOR_ARTICLES_SUCCEEDED";

export function fetchEvents() {
  return { type: FETCH_EVENTS };
}
export function fetchEventDetail(id) {
  return { type: FETCH_EVENT_DETAIL, payload: id };
}
export function fetchHomePosts(currentDateTime) {
  return { type: FETCH_HOME_POSTS, payload: currentDateTime };
}
export function fetchPosts(currentDateTime) {
  return { type: FETCH_POSTS, payload: currentDateTime };
}

export function fetchPost(id) {
  return { type: FETCH_POST, payload: id };
}

// export function fetchCategories() {
//   return { type: FETCH_CATEGORIES };
// }

// export function fetchCategory(category, currentDateTime) {
//   return { type: FETCH_CATEGORY, payload: {category, currentDateTime} };
// }

export function fetchCategory(category, currentDateTime) {
  return { type: FETCH_CATEGORY, payload: {category, currentDateTime} };
}

export function fetchRelatedPosts(slug, currentDateTime, inlineIds) {
  return { type: FETCH_RELATEDPOSTS, payload: {slug, currentDateTime, inlineIds} };
}

export function fetchSearchResult(xxx) {
  return { type: FETCH_SEARCH_RESULT, payload: xxx };
}

export function fetchTags() {
  return { type: FETCH_TAGS };
}

export function fetchTag(tag, currentDateTime) {
  return { type: FETCH_TAG, payload: {tag, currentDateTime}};
}

export function fetchTagArticles(tagArticles, currentDateTime) {
  return { type: FETCH_TAG_ARTICLES, payload: {tagArticles, currentDateTime}};
}

export function fetchTagVideos(tagVideos, currentDateTime) {
  return { type: FETCH_TAG_VIDEOS, payload: {tagVideos, currentDateTime}};
}

export function fetchBrandProfile(brandProfile, currentDateTime) {
  return { type: FETCH_BRAND_PROFILE, payload: {brandProfile, currentDateTime} };
}
export function fetchBrandVideos(brandVideos, currentDateTime) {
  return { type: FETCH_BRAND_VIDEOS, payload: {brandVideos, currentDateTime} };
}
export function fetchBrandEvents(brandEvents, currentDateTime, perPage) {
  return { type: FETCH_BRAND_EVENTS, payload: {brandEvents, currentDateTime, perPage} };
}

export function fetchBrands() {
  return { type: FETCH_BRANDS };
}

// export function fetchCategoryMobile(categoryMobile, currentDateTime) {
//   return { type: FETCH_CATEGORY_MOBILE, payload: {categoryMobile, currentDateTime} };
// }

// export function fetchCategoryDesktop(categoryDesktop, currentDateTime) {
//   return { type: FETCH_CATEGORY_DESKTOP, payload: {categoryDesktop, currentDateTime} };
// }
export function fetchPopularItems(slug, currentDateTime) {
  return { type: FETCH_POPULAR_ITEMS, payload: {slug, currentDateTime}};
}
export function fetchPopularPosts(slug, currentDateTime) {
  return { type: FETCH_POPULAR_POSTS, payload: {slug, currentDateTime}};
}

export function fetchVideos(currentDateTime, type, perPage) {
  return { type: FETCH_VIDEOS, payload: {currentDateTime, type, perPage }};
}
export function fetchVideo(id) {
  return { type: FETCH_VIDEO, payload: id };
}

export function fetchRelatedVideos(relatedVideos, currentDateTime, type) {
  return { type: FETCH_RELATED_VIDEOS, payload: {relatedVideos, currentDateTime, type} };
}

export function fetchPlaylist(list) {
  return { type: FETCH_PLAYLIST_VIDEOS, payload: list };
}

export function fetchCategoryVideos(categoryVideos, currentDateTime, type) {
  return { type: FETCH_CATEGORY_VIDEOS, payload: {categoryVideos, currentDateTime, type} };
}

export function fetchAuthorProfile(id) {
  return { type: FETCH_AUTHOR_PROFILE, payload: id };
}
export function fetchAuthorVideos(id, currentDateTime, perPage) {
  return { type: FETCH_AUTHOR_VIDEOS, payload: {id, currentDateTime, perPage} };
}
export function fetchAuthorArticles(id, currentDateTime, perPage) {
  return { type: FETCH_AUTHOR_ARTICLES, payload: {id, currentDateTime, perPage} };
}
