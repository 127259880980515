import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import createSagaMiddleware, { END } from "redux-saga";
import rootSaga from "store/sagas";
import reducer from "store/reducer";

// Setup
const middleWare = [];

// Saga Middleware
const sagaMiddleware = createSagaMiddleware();
middleWare.push(sagaMiddleware);

// Logger Middleware. This always has to be last
const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === "development"
});
middleWare.push(loggerMiddleware);

const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(...middleWare)
)(createStore);

const makeStore = (initialState, options) => {
  const store = createStoreWithMiddleware(reducer, initialState);

  // store.runSagaTask = () => {
  //   store.sagaTask = sagaMiddleware.run(rootSaga);
  // };
  // // run the rootSaga initially
  // store.runSagaTask();

  store.runSagaTask = () => {
    // Avoid running twice
    // if (store.sagaTask) return;
    store.sagaTask = sagaMiddleware.run(rootSaga);
  };

  store.stopSaga = async () => {
    // Avoid running twice
    if (!store.sagaTask) return;
    store.dispatch(END);
    await store.sagaTask.done;
    store.sagaTask = false;
  };

  store.execSagaTasks = async (isServer, tasks) => {
    console.log('store.runSagaTask():isServer:', isServer);
    // run saga
    store.runSagaTask();
    // dispatch saga tasks
    tasks(store.dispatch);
    // Stop running and wait for the tasks to be done
    await store.stopSaga();
    // Re-run on client side
    if (!isServer) {
      console.log('store.runSagaTask():isServer:', isServer);
      store.runSagaTask();
    }
  };

  // Initial run
  store.runSagaTask();  

  return store;
};

export default makeStore;
