import {
  FETCH_POSTS_SUCCEEDED,
  FETCH_POST_SUCCEEDED,
  FETCH_POST_FAILED,
  // FETCH_CATEGORIES_SUCCEEDED,
  FETCH_CATEGORY_SUCCEEDED,
  FETCH_CATEGORY_FAILED,  
  FETCH_RELATEDPOSTS_SUCCEEDED,
  FETCH_TAGS_SUCCEEDED,
  FETCH_TAG_SUCCEEDED,
  FETCH_SEARCH_RESULT_SUCCEEDED,
  FETCH_SEARCH_RESULT_FAILED,
  FETCH_BRAND_PROFILE,
  FETCH_BRAND_PROFILE_SUCCEEDED,
  FETCH_BRAND_PROFILE_FAILED,
  FETCH_BRANDS_SUCCEEDED,
  FETCH_BRANDS_FAILED,
  FETCH_POPULAR_ITEMS_SUCCEEDED,
  FETCH_POPULAR_POSTS_SUCCEEDED,
  FETCH_POPULAR_POSTS_FAILED,
  FETCH_VIDEOS,
  FETCH_VIDEOS_SUCCEEDED,
  FETCH_VIDEO,
  FETCH_VIDEO_SUCCEEDED,
  FETCH_VIDEO_FAILED,
  FETCH_RELATED_VIDEOS,
  FETCH_RELATED_VIDEOS_SUCCEEDED,
  FETCH_TAG_ARTICLES_SUCCEEDED,
  FETCH_TAG_VIDEOS_SUCCEEDED,
  FETCH_PLAYLIST_VIDEOS_SUCCEEDED,
  FETCH_PLAYLIST_VIDEOS_FAILED,
  FETCH_CATEGORY_VIDEOS_SUCCEEDED,
  FETCH_CATEGORY_VIDEOS_FAILED,
  FETCH_HOME_POSTS_SUCCEEDED,
  FETCH_HOME_POSTS_FAILED,
  FETCH_EVENTS_SUCCEEDED,
  FETCH_EVENT_DETAIL_SUCCEEDED,
  FETCH_BRAND_VIDEOS_SUCCEEDED,
  FETCH_BRAND_EVENTS_SUCCEEDED,
  FETCH_AUTHOR_PROFILE_SUCCEEDED,
  FETCH_AUTHOR_ARTICLES_SUCCEEDED,
  FETCH_AUTHOR_VIDEOS_SUCCEEDED,
} from "store/posts/actions";

function popularItems(state = [], action) {
  switch (action.type) {
    case FETCH_POPULAR_ITEMS_SUCCEEDED:
    case FETCH_POPULAR_POSTS_SUCCEEDED:
      return [
          ...state,
          {
            popularItems: action.payload
          }
        ]
    default:
      return state
  }
}
function popularPosts(state = [], action) {
  let obj = {slug : action.payload.data.slug};
  let existingIndex = state.findIndex(item => item.slug == obj.slug);
  if(existingIndex > -1) {
    state[existingIndex][obj.slug] = action.payload;
  }
  else {
    obj[obj.slug] = action.payload;
    state.push(obj);
  }
  return state;
}
export default function(state = [], action) {
  switch (action.type) {
    case FETCH_AUTHOR_PROFILE_SUCCEEDED:
      console.log(':::authorProfile')
      console.log(state)
      return { ...state, authorProfile: action.payload };
    case FETCH_AUTHOR_VIDEOS_SUCCEEDED:
      return { ...state, authorVideos: action.payload };
    case FETCH_AUTHOR_ARTICLES_SUCCEEDED:
      return { ...state, authorArticles: action.payload };
    case FETCH_BRAND_EVENTS_SUCCEEDED:
      return { ...state, brandEvents: action.payload };
    case FETCH_BRAND_VIDEOS_SUCCEEDED:
      return { ...state, brandVideos: action.payload };
    case FETCH_EVENTS_SUCCEEDED:
      return { ...state, events: action.payload };
    case FETCH_EVENT_DETAIL_SUCCEEDED:
      return { ...state, eventDetail: action.payload };
    case FETCH_HOME_POSTS_SUCCEEDED:
      return { ...state, homePosts: action.payload };
    case FETCH_HOME_POSTS_FAILED:
      console.log("BEGIN::FETCH_HOME_POSTS_FAILED")
      console.log(state)
      console.log("END::FETCH_HOME_POSTS_FAILED")    
      return { ...state, error: action.payload };
    case FETCH_POSTS_SUCCEEDED:
      return { ...state, list: action.payload };
    case FETCH_POST_SUCCEEDED:
      return { ...state, currentPost: action.payload };
    case FETCH_POST_FAILED:
      console.log("BEGIN::FETCH_POST_FAILED")
      console.log(state)
      console.log("END::FETCH_POST_FAILED")
      return { ...state, error: action.payload };      
    // case FETCH_CATEGORIES_SUCCEEDED:
    //   return { ...state, categories: action.payload };
    case FETCH_CATEGORY_SUCCEEDED:
      return { ...state, currentCategory: action.payload };
    case FETCH_CATEGORY_FAILED:
      console.log("BEGIN::FETCH_CATEGORY_FAILED")
      console.log(state)
      console.log("END::FETCH_CATEGORY_FAILED")
      return { ...state, error: action.payload };        
    case FETCH_RELATEDPOSTS_SUCCEEDED:
      return { ...state, relatedPosts: action.payload };
    case FETCH_TAGS_SUCCEEDED:
      return { ...state, tags: action.payload };
    case FETCH_TAG_SUCCEEDED:
      return { ...state, tag: action.payload };
    case FETCH_SEARCH_RESULT_SUCCEEDED:
      return { ...state, searchResult: action.payload };
    case FETCH_SEARCH_RESULT_FAILED:
      return { ...state, searchResult: action.payload };
    case FETCH_BRAND_PROFILE_SUCCEEDED:
      return { ...state, brandProfile: action.payload };
    case FETCH_BRAND_PROFILE_FAILED:
      console.log("BEGIN::FETCH_BRAND_PROFILE_FAILED")
      console.log(state)
      console.log("END::FETCH_BRAND_PROFILE_FAILED")
      return { ...state, error: action.payload };       
    case FETCH_BRANDS_SUCCEEDED:
      return { ...state, brands: action.payload };
    case FETCH_BRANDS_FAILED:
      console.log("BEGIN::FETCH_BRANDS_FAILED")
      console.log(state)
      console.log("END::FETCH_BRANDS_FAILED")
      return { ...state, error: action.payload };      
      // return { ...state, brands: action.payload };
    case FETCH_POPULAR_ITEMS_SUCCEEDED:
      return Object.assign({}, state, {
        popularPosts: popularItems(state.popularPosts, action)
      });
    case FETCH_POPULAR_POSTS_SUCCEEDED:
      console.log('reducer:FETCH_POPULAR_POSTS_SUCCEEDED:', action.payload);
      return Object.assign({}, state, {
        popularPosts: popularPosts(state.popularPosts, action)
      }); 
    case FETCH_POPULAR_POSTS_FAILED:
      console.log("BEGIN::FETCH_POPULAR_POSTS_FAILED")
      console.log(state)
      console.log("END::FETCH_POPULAR_POSTS_FAILED")
      return { ...state, error: action.payload };          
    case FETCH_VIDEOS_SUCCEEDED:
      return { ...state, videos: action.payload };
    case FETCH_VIDEO_SUCCEEDED:
      return { ...state, currentVideo: action.payload };
    case FETCH_VIDEO_FAILED:
      console.log("BEGIN::FETCH_VIDEO_FAILED")
      console.log(state)
      console.log("END::FETCH_VIDEO_FAILED")
      return { ...state, error: action.payload };        
    case FETCH_RELATED_VIDEOS_SUCCEEDED:
      return { ...state, relatedVideos: action.payload };
    case FETCH_TAG_ARTICLES_SUCCEEDED:
      return { ...state, tagArticles: action.payload };
    case FETCH_TAG_VIDEOS_SUCCEEDED:
      return { ...state, tagVideos: action.payload };
    case FETCH_PLAYLIST_VIDEOS_SUCCEEDED:
      return { ...state, playlist: action.payload };
    case FETCH_PLAYLIST_VIDEOS_FAILED:
      console.log("BEGIN::FETCH_PLAYLIST_VIDEOS_FAILED")
      console.log(state)
      console.log("END::FETCH_PLAYLIST_VIDEOS_FAILED")
      return { ...state, error: action.payload };      
    case FETCH_CATEGORY_VIDEOS_SUCCEEDED:
      return { ...state, categoryVideos: action.payload };
    case FETCH_CATEGORY_VIDEOS_FAILED:
      console.log("BEGIN::FETCH_CATEGORY_VIDEOS_FAILED")
      console.log(state)
      console.log("END::FETCH_CATEGORY_VIDEOS_FAILED")
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
