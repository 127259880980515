import { takeEvery, takeLatest, fork, call, put, all } from "redux-saga/effects";

import api from "services/api";
import {c} from '/utils/log';

import {
  FETCH_EVENTS,
  FETCH_EVENTS_SUCCEEDED,
  FETCH_POSTS,
  FETCH_POSTS_SUCCEEDED,
  FETCH_POST,
  FETCH_POST_FAILED,
  FETCH_POST_SUCCEEDED,
  // FETCH_CATEGORIES_SUCCEEDED,
  // FETCH_CATEGORIES,
  FETCH_CATEGORY,
  FETCH_CATEGORY_SUCCEEDED,
  FETCH_CATEGORY_FAILED,
  FETCH_RELATEDPOSTS,
  FETCH_RELATEDPOSTS_SUCCEEDED,
  FETCH_TAGS_SUCCEEDED,
  FETCH_TAGS,
  FETCH_TAG_SUCCEEDED,
  FETCH_TAG,
  FETCH_SEARCH_RESULT,
  FETCH_SEARCH_RESULT_SUCCEEDED,
  FETCH_SEARCH_RESULT_FAILED,
  FETCH_BRAND_PROFILE,
  FETCH_BRAND_PROFILE_SUCCEEDED,
  FETCH_BRAND_PROFILE_FAILED,
  FETCH_BRANDS,
  FETCH_BRANDS_SUCCEEDED,
  FETCH_BRANDS_FAILED,
  FETCH_POPULAR_ITEMS,
  FETCH_POPULAR_ITEMS_SUCCEEDED,
  FETCH_POPULAR_POSTS,
  FETCH_POPULAR_POSTS_SUCCEEDED,
  FETCH_POPULAR_POSTS_FAILED,
  FETCH_VIDEOS,
  FETCH_VIDEOS_SUCCEEDED,
  FETCH_VIDEO,
  FETCH_VIDEO_SUCCEEDED,
  FETCH_VIDEO_FAILED,
  FETCH_RELATED_VIDEOS,
  FETCH_RELATED_VIDEOS_SUCCEEDED,
  FETCH_TAG_VIDEOS,
  FETCH_TAG_VIDEOS_SUCCEEDED,
  FETCH_TAG_ARTICLES,
  FETCH_TAG_ARTICLES_SUCCEEDED,
  FETCH_PLAYLIST_VIDEOS,
  FETCH_PLAYLIST_VIDEOS_SUCCEEDED,
  FETCH_PLAYLIST_VIDEOS_FAILED,
  FETCH_CATEGORY_VIDEOS,
  FETCH_CATEGORY_VIDEOS_SUCCEEDED,
  FETCH_CATEGORY_VIDEOS_FAILED,
  FETCH_HOME_POSTS,
  FETCH_HOME_POSTS_SUCCEEDED,
  FETCH_HOME_POSTS_FAILED,
  FETCH_EVENT_DETAIL,
  FETCH_EVENT_DETAIL_SUCCEEDED,
  FETCH_BRAND_VIDEOS,
  FETCH_BRAND_VIDEOS_SUCCEEDED,
  FETCH_BRAND_EVENTS,
  FETCH_BRAND_EVENTS_SUCCEEDED,
  FETCH_AUTHOR_ARTICLES,
  FETCH_AUTHOR_ARTICLES_SUCCEEDED,
  FETCH_AUTHOR_VIDEOS,
  FETCH_AUTHOR_VIDEOS_SUCCEEDED,
  FETCH_AUTHOR_PROFILE,
  FETCH_AUTHOR_PROFILE_SUCCEEDED
} from "store/posts/actions";
// import { fetchTagArticles } from "./actions";
// import { fetchBrandProfile } from "./actions";

function* fetchHomePosts({payload}) {
  try {
    const homePosts = yield call(api.fetchHomePosts, payload);
    yield put({ type: FETCH_HOME_POSTS_SUCCEEDED, payload: homePosts.data });
  }
  catch (err) {
    yield put({ type: FETCH_HOME_POSTS_FAILED, payload: { status : err.response.status } });    
  }
}

function* fetchPosts({payload}) {
  const posts = yield call(api.fetchAllPosts, payload);
  yield put({ type: FETCH_POSTS_SUCCEEDED, payload: posts.data });
}

function* fetchPost({ payload }) {
  try {
    const {data, error} = yield call(api.fetchPost, payload)
    c.log("fetchPost:data")
    c.log(data)
    yield put({ type: FETCH_POST_SUCCEEDED, payload: data });
  }
  catch (err) {
    c.log("fetchPost:Error")
    c.log(err.response)
    yield put({ type: FETCH_POST_FAILED, payload: { status : err.response.status } });    
  }
}

function* fetchPost0({ payload }) {
  const {data, error} = yield call(api.fetchPost, payload)
  // res.data.data.status = res.status;
  if(!error){
    c.log("fetchPost:no-error:")
    yield put({ type: FETCH_POST_SUCCEEDED, payload: data })
  }
  else {
    // c.log("fetchPost:found-error:")
    // c.log(error)
    // throw error
    const err = new Error()
    // err.code = 'ENOENT'
    err.status = 404
    err.statusCode = 404
    throw err
  }
}

// function* fetchCategories({ action }) {
//   const categories = yield call(api.fetchCategories);
//   yield put({ type: FETCH_CATEGORIES_SUCCEEDED, payload: categories.data.data });
// }

function* fetchCategory({ payload }) {
  try {
    const category = yield call(api.fetchCategory, payload.category, payload.currentDateTime);
    yield put({ type: FETCH_CATEGORY_SUCCEEDED, payload: category.data });
  }
  catch (err) {
    c.log("fetchPost:Error")
    c.log(err.response)
    yield put({ type: FETCH_CATEGORY_FAILED, payload: { status : err.response.status } });    
  }
}

function* fetchRelatedPosts({ payload }) {
  const relatedPosts = yield call(api.fetchRelatedPosts, payload.slug, payload.currentDateTime, payload.inlineIds);
  yield put({ type: FETCH_RELATEDPOSTS_SUCCEEDED, payload: relatedPosts.data });
}

function* fetchSearchResult({ payload }) {
  const searchResult = yield call(api.fetchSearchResult, payload);
  try {
    yield put({ type: FETCH_SEARCH_RESULT_SUCCEEDED, payload: searchResult.data });
  }
  catch (error) {
    yield put({ type: FETCH_SEARCH_RESULT_FAILED, payload: error });    
  }
}

function* fetchTags({ action }) {
  const tags = yield call(api.fetchTags);
  yield put({ type: FETCH_TAGS_SUCCEEDED, payload: tags.data.data });
}

function* fetchBrandProfile({ payload }) {
  try {
    const res = yield call(api.fetchBrandProfile, payload.brandProfile, payload.currentDateTime)
    yield put({ type: FETCH_BRAND_PROFILE_SUCCEEDED, payload: res.data })
  }
  catch (err) {
    yield put({ type: FETCH_BRAND_PROFILE_FAILED, payload: { status : err.response.status } });    
  }

}
function* fetchBrandVideos({ payload }) {
  try {
    const res = yield call(api.fetchBrandVideos, payload.brandVideos, payload.currentDateTime)
    yield put({ type: FETCH_BRAND_VIDEOS_SUCCEEDED, payload: res.data })
  }
  catch (err) {
    yield put({ type: FETCH_POST_FAILED, payload: { status : err.response.status } });     
  }
}
function* fetchBrandEvents({ payload }) {
  try {
    const res = yield call(api.fetchBrandEvents, payload.brandEvents, payload.currentDateTime, payload.perPage)
    yield put({ type: FETCH_BRAND_EVENTS_SUCCEEDED, payload: res.data })
  }
  catch (err) {
    yield put({ type: FETCH_POST_FAILED, payload: { status : err.response.status } });     
  }
}
function* fetchTag({ payload }) {
  const tag = yield call(api.fetchTag, payload.tag, payload.currentDateTime);
  yield put({ type: FETCH_TAG_SUCCEEDED, payload: tag.data });
}

function* fetchTagArticles({ payload }) {
  const tagArticles = yield call(api.fetchTagArticles, payload.tagArticles, payload.currentDateTime);
  yield put({ type: FETCH_TAG_ARTICLES_SUCCEEDED, payload: tagArticles.data });
}

function* fetchTagVideos({ payload }) {
  const tagVideos = yield call(api.fetchTagVideos, payload.tagVideos, payload.currentDateTime);
  yield put({ type: FETCH_TAG_VIDEOS_SUCCEEDED, payload: tagVideos.data });
}

function* fetchBrands({ action }) {
  const brands = yield call(api.fetchBrands);
  yield put({ type: FETCH_BRANDS_SUCCEEDED, payload: brands.data.data });
}

function* fetchPopularItems({ payload }) {
  const slugArr = payload.slug;
  const popularItems = yield all(slugArr.map(p => call(api.fetchPopularItems, p, payload.currentDateTime)));
  for(let i = 0; i < popularItems.length; i++){
    yield put({ type: FETCH_POPULAR_ITEMS_SUCCEEDED, payload: popularItems[i].data});
  }
}

function* fetchPopularPosts({ payload }) {
  try {
    const popularPosts = yield call(api.fetchPopularPosts, payload.slug, payload.currentDateTime);
    yield put({ type: FETCH_POPULAR_POSTS_SUCCEEDED, payload: popularPosts.data });
  }
  catch (err) {
    yield put({ type: FETCH_POPULAR_POSTS_FAILED, payload: { status : err.response.status } });    
  }
}

function* fetchVideos({payload}) {
  const videos = yield call(api.fetchAllVideos, payload.currentDateTime, payload.type, payload.perPage);
  yield put({ type: FETCH_VIDEOS_SUCCEEDED, payload: videos.data });
}

function* fetchVideo({ payload }) {
  try {
    const res = yield call(api.fetchVideo, payload)
    yield put({ type: FETCH_VIDEO_SUCCEEDED, payload: res.data })
  }
  catch (err) {
    yield put({ type: FETCH_VIDEO_FAILED, payload: { status : err.response.status } });    
  }
}

function* fetchRelatedVideos({ payload }) {
  const relatedVideos = yield call(api.fetchRelatedVideos, payload.relatedVideos, payload.currentDateTime, payload.type);
  yield put({ type: FETCH_RELATED_VIDEOS_SUCCEEDED, payload: relatedVideos.data });
}

function* fetchPlaylist({ payload }) {
  try {
    const playlist = yield call(api.fetchPlaylist, payload);
    yield put({ type: FETCH_PLAYLIST_VIDEOS_SUCCEEDED, payload: playlist.data });
  }
  catch (err) {
    yield put({ type: FETCH_PLAYLIST_VIDEOS_FAILED, payload: { status : err.response.status } });    
  }
}

function* fetchCategoryVideos({ payload }) {
  try {
    const categoryVideos = yield call(api.fetchCategoryVideos, payload.categoryVideos, payload.currentDateTime, payload.type);
    yield put({ type: FETCH_CATEGORY_VIDEOS_SUCCEEDED, payload: categoryVideos.data });
  }
  catch (err) {
    yield put({ type: FETCH_CATEGORY_VIDEOS_FAILED, payload: { status : err.response.status } });    
  }
}
function* fetchEvents() {
  const events = yield call(api.fetchEvents);
  yield put({ type: FETCH_EVENTS_SUCCEEDED, payload: events.data });
}

function* fetchEventDetail({ payload }) {

  try {
    const eventDetail = yield call(api.fetchEventDetail, payload);
    yield put({ type: FETCH_EVENT_DETAIL_SUCCEEDED, payload: eventDetail.data });
  }
  catch (err) {
    yield put({ type: FETCH_POST_FAILED, payload: { status : err.response.status } });    
  }
}


function* fetchAuthorProfile({ payload }) {
  try {
    const res = yield call(api.fetchAuthorProfile, payload)
    yield put({ type: FETCH_AUTHOR_PROFILE_SUCCEEDED, payload: res.data })
  }
  catch (err) {
    yield put({ type: FETCH_POST_FAILED, payload: { status : err.response.status } });    
  }

}
function* fetchAuthorVideos({ payload }) {
  try {
    const res = yield call(api.fetchAuthorVideos, payload.id, payload.currentDateTime, payload.perPage)
    yield put({ type: FETCH_AUTHOR_VIDEOS_SUCCEEDED, payload: res.data })
  }
  catch (err) {
    yield put({ type: FETCH_POST_FAILED, payload: { status : err.response.status } });     
  }
}
function* fetchAuthorArticles({ payload }) {
  try {
    const res = yield call(api.fetchAuthorArticles, payload.id, payload.currentDateTime, payload.perPage)
    yield put({ type: FETCH_AUTHOR_ARTICLES_SUCCEEDED, payload: res.data })
  }
  catch (err) {
    yield put({ type: FETCH_POST_FAILED, payload: { status : err.response.status } });     
  }
}
function* watchFetchPopularItems() {
  yield takeLatest( FETCH_POPULAR_ITEMS, fetchPopularItems);
}
function* watchFetchPopularPosts() {
  yield takeEvery( FETCH_POPULAR_POSTS, fetchPopularPosts);
}

function* watchFetchAuthorProfile() {
  yield takeLatest( FETCH_AUTHOR_PROFILE, fetchAuthorProfile);
}

function* watchFetchAuthorVideos() {
  yield takeLatest(FETCH_AUTHOR_VIDEOS, fetchAuthorVideos);
}

function* watchFetchAuthorArticles() {
  yield takeLatest(FETCH_AUTHOR_ARTICLES, fetchAuthorArticles);
}

function* watchFetchEventDetail() {
  yield takeLatest(FETCH_EVENT_DETAIL, fetchEventDetail);
}

function* watchFetchEvents() {
  yield takeLatest(FETCH_EVENTS, fetchEvents);
}

function* watchFetchHomePosts() {
  yield takeLatest(FETCH_HOME_POSTS, fetchHomePosts);
}

function* watchFetchPosts() {
  yield takeLatest(FETCH_POSTS, fetchPosts);
}

function* watchFetchPost() {
  yield takeLatest(FETCH_POST, fetchPost);
  // yield fork(FETCH_POST, fetchPost);
  // // while (true) {
  // //   const {payload} = yield take('REQUEST')
  // //   yield fork(handleRequest, payload)
  // // }
}

// function* watchFetchCategories() {
//   yield takeLatest(FETCH_CATEGORIES, fetchCategories);
// }

function* watchFetchCategory() {
  yield takeLatest(FETCH_CATEGORY, fetchCategory);
}

function* watchFetchRelatedPosts() {
  yield takeLatest(FETCH_RELATEDPOSTS, fetchRelatedPosts);
}

// function* watchFetchTags() {
//   yield takeLatest(FETCH_TAGS, fetchTags);
// }

function* watchFetchTag() {
  yield takeLatest(FETCH_TAG, fetchTag);
}

function* watchFetchTagArticles() {
  yield takeLatest(FETCH_TAG_ARTICLES, fetchTagArticles);
}

function* watchFetchTagVideos() {
  yield takeLatest(FETCH_TAG_VIDEOS, fetchTagVideos);
}

function* watchFetchSearchResult() {
  yield takeLatest(FETCH_SEARCH_RESULT, fetchSearchResult);
}

function* watchFetchBrandProfile() {
  yield takeLatest(FETCH_BRAND_PROFILE, fetchBrandProfile);
}
function* watchFetchBrandVideos() {
  yield takeLatest(FETCH_BRAND_VIDEOS, fetchBrandVideos);
}
function* watchFetchBrandEvents() {
  yield takeLatest(FETCH_BRAND_EVENTS, fetchBrandEvents);
}

function* watchFetchBrands() {
  yield takeLatest(FETCH_BRANDS, fetchBrands);
}

function* watchFetchVideos() {
  yield takeLatest(FETCH_VIDEOS, fetchVideos);
}

function* watchFetchVideo() {
  yield takeLatest(FETCH_VIDEO, fetchVideo);
}

function* watchFetchRelatedVideos() {
  yield takeLatest(FETCH_RELATED_VIDEOS, fetchRelatedVideos);
}

function* watchFetchPlaylist() {
  yield takeLatest(FETCH_PLAYLIST_VIDEOS, fetchPlaylist);
}

function* watchFetchCategoryVideos() {
  yield takeLatest(FETCH_CATEGORY_VIDEOS, fetchCategoryVideos);
}
export default function* postsSagas() {
  yield fork(watchFetchHomePosts);
  yield fork(watchFetchPosts);
  yield fork(watchFetchPost);
  // yield fork(watchFetchCategories);
  yield fork(watchFetchCategory);
  yield fork(watchFetchRelatedPosts);
  // yield fork(watchFetchTags);
  yield fork(watchFetchTag);
  yield fork(watchFetchSearchResult);
  yield fork(watchFetchBrandProfile);
  yield fork(watchFetchBrands);
  yield fork(watchFetchVideos);
  yield fork(watchFetchVideo);
  yield fork(watchFetchRelatedVideos);
  yield fork(watchFetchTagArticles);
  yield fork(watchFetchTagVideos);
  yield fork(watchFetchPlaylist);
  yield fork(watchFetchCategoryVideos);
  yield fork(watchFetchEvents);
  yield fork(watchFetchEventDetail);
  yield fork(watchFetchBrandVideos);
  yield fork(watchFetchBrandEvents);
  yield fork(watchFetchAuthorProfile);
  yield fork(watchFetchAuthorVideos);
  yield fork(watchFetchAuthorArticles);
  // yield fork(watchFetchPopularItems);
  yield fork(watchFetchPopularPosts);
}
