import axios from "axios";
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';

const facade = {};

// const api = axios.create({ baseURL: "https://jsonplaceholder.typicode.com" });
const api = axios.create({ 
  baseURL: process.env.API_URL ? process.env.API_URL : 'https://cms.duwun.com.mm/api',
  headers: { 'Cache-Control': 'no-cache' },
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter))
});

facade.request = config => api.request(config);
["get", "head"].forEach(method => {
  facade[method] = (url, config) => facade.request({ ...config, method, url });
});
["delete", "post", "put", "patch"].forEach(method => {
  facade[method] = (url, data, config) =>
    facade.request({ ...config, method, url, data });
});

class API {
  static fetchAllPosts(currentDateTime) {
    return facade.get(`/news/latest?published_date=${currentDateTime}&exclude=body&page=1`);
  }

  static fetchPost(id) {
    return facade.get(encodeURI(`/news/${id}`));
    // .then(response => {
    //   return response;
    // })
    // .catch(function(error){
    //   if(error.response){
    //     console.log(error.response.status)
    //     return error.response;
    //   }
    // })
    // ;
  }
  // static fetchCategories() {
  //   return facade.get(`/categories`);
  // }
  // static fetchCategory(category, currentDateTime) {
  //   return facade.get(`/categories/${category}?exclude=body&page=1&published_date=${currentDateTime}`);
  // }
  static fetchCategory(category, currentDateTime) {
    return facade.get(`/categories/${category}?exclude=body&page=1&published_date=${currentDateTime}&perPage=8`);
  }
  // static fetchCategoryDesktop(category, currentDateTime) {
  //   return facade.get(`/categories/${category}?exclude=body&page=1&published_date=${currentDateTime}&perPage=16`);
  // }
  // static fetchCategoryMobile(category, currentDateTime) {
  //   return facade.get(`/categories/${category}?exclude=body&page=1&published_date=${currentDateTime}&perPage=6`);
  // }
  static fetchTags() {
    return facade.get(`/tags`);
  }
  static fetchTag(tag, currentDateTime) {
    return facade.get(encodeURI(`/tags/${tag}?exclude=body&published_date=${currentDateTime}&perPage=8&type=all`));
  }
  static fetchTagArticles(tagArticles, currentDateTime) {
    return facade.get(encodeURI(`/articles/tags/${tagArticles}?exclude=body&published_date=${currentDateTime}&perPage=8`));
  }
  static fetchTagVideos(tagVideos, currentDateTime) {
    return facade.get(encodeURI(`/videos/tags/${tagVideos}?exclude=body&published_date=${currentDateTime}&perPage=8`));
  }

  static fetchRelatedPosts(slug, currentDateTime, inlineIds) {
    return facade.get(`/news/${slug}/related?exclude=body&perPage=4&published_date=${currentDateTime}&inlineIds=${inlineIds}`);
  }
  static fetchSearchResult(query) {
    return facade.get(encodeURI(`/search?q=${query.q}&exclude=body&type=${query.sc}&page=${query.page}&sortBy=${query.sort}`));
  }
  static fetchBrandProfile(slug, currentDateTime) {
    return facade.get(`/brands/${slug}?exclude=body&published_date=${currentDateTime}&perPage=8&page=1`);
  }
  static fetchBrandVideos(slug, currentDateTime) {
    return facade.get(`/brands/${slug}/videos?exclude=body&published_date=${currentDateTime}&perPage=8&page=1`);
  }
  static fetchBrandEvents(slug, currentDateTime, perPage) {
    return facade.get(`/brands/${slug}/events?exclude=body&published_date=${currentDateTime}&perPage=${perPage}&page=1`);
  }
  static fetchBrands() {
    return facade.get(`/brands`);
  }
  static fetchPopularItems(slug, currentDateTime) {
    return facade.get(`/categories/${slug}/popular?exclude=body&published_date=${currentDateTime}`)
  }
  static fetchPopularPosts(slug, currentDateTime) {
    return facade.get(`/categories/${slug}/popular?exclude=body&published_date=${currentDateTime}`)
  }  
  static fetchPopular(currentDateTime) {
    return facade.get(`/categories/tech/popular?exclude=body&published_date=${currentDateTime}`)
  }
  static fetchPopularNews(currentDateTime) {
    return facade.get(`/categories/news/popular?exclude=body&published_date=${currentDateTime}`);
  }
  static fetchPopularEntertainment(currentDateTime) {
    return facade.get(`/categories/entertainment/popular?exclude=body&published_date=${currentDateTime}`);
  }
  static fetchPopularSportNews(currentDateTime) {
    return facade.get(`/categories/sport/popular?exclude=body&published_date=${currentDateTime}`);
  }
  static fetchPopularWomen(currentDateTime) {
    return facade.get(`/categories/election-2020/popular?exclude=body&published_date=${currentDateTime}`);
  }
  static fetchPopularLifestyle(currentDateTime) {
    return facade.get(`/categories/lifestyle/popular?exclude=body&published_date=${currentDateTime}`);
  }
  static fetchPopularTech(currentDateTime) {
    return facade.get(`/categories/tech/popular?exclude=body&published_date=${currentDateTime}`);
  }
  static fetchAllVideos(currentDateTime, type, perPage) {
    return facade.get(`/videos/latest?type=${type}&published_date=${currentDateTime}&exclude=body&page=1&perPage=${perPage}`);
  }
  static fetchVideo(id) {
    return facade.get(`/videos/${id}`); 
  }
  static fetchRelatedVideos(relatedVideos, currentDateTime, type) {
    return facade.get(`/videos/${relatedVideos}/related?type=${type}&exclude=body&perPage=8&published_date=${currentDateTime}`);
  }
  static fetchPlaylist(playlistId) {
    return facade.get(`/playlists/${playlistId}`); 
  }
  static fetchCategoryVideos(categoryVideos, currentDateTime, type) {
    return facade.get(`/videos/categories/${categoryVideos}?type=${type}&perPage=8&published_date=${currentDateTime}`);
  }
  static fetchHomePosts(currentDateTime) {
    return facade.get(`/pages/home?exclude=body&published_date=${currentDateTime}`);
  } 
  static fetchEvents() {
    return facade.get(`/events?perPage=8`);
  } 
  static fetchEventDetail(id) {
    return facade.get(encodeURI(`/events/${id}`));
  }
  static fetchAuthorProfile(id) {
    return facade.get(`/authors/${id}`);
  }
  static fetchAuthorVideos(id, currentDateTime, perPage) {
    return facade.get(`/authors/${id}/videos?exclude=body&published_date=${currentDateTime}&perPage=${perPage}&page=1`);
  }
  static fetchAuthorArticles(id, currentDateTime, perPage) {
    return facade.get(`/authors/${id}/articles?exclude=body&published_date=${currentDateTime}&perPage=${perPage}&page=1`);
  }
}

export default API;
